import { LabradorComponent } from '@/types/component';
import { tw } from '@/utils/tw';
import { LabradorRichTextBox } from 'base/components/labrador/RichTextBox';

export const HantLabradorRichTextBox: LabradorComponent = (props) => {
  // (hopefully) temporary hack
  const isHantDirectBanner = String(props.data.content).includes('HÄNT DIREKT:');
  const hantDirectBannerStyles = tw.join(['bg-white', 'not-prose', 'text-headline-md']);

  props.data.content = props.data.content.replace(/-(red|yellow|blue)-(\d{3})/g, '-$1');

  return <LabradorRichTextBox options={{ className: isHantDirectBanner ? hantDirectBannerStyles : '' }} {...props} />;
};
