import { tw } from '@/utils/tw';
import BaseExpertSliderTheme from 'base/components/ExpertSlider/theme';

const Slider = tw.theme({
  extend: BaseExpertSliderTheme.Slider,
  slots: {
    slide: ['p-0', 'mx-1', 'first:ml-0', 'last:mr-0'],
  },
  variants: {
    aspectRatio: {
      vertical: {
        slide: ['sm:w-54', 'md:w-76'],
      },
    },
  },
});

const Image = tw.theme({
  extend: BaseExpertSliderTheme.Image,
  slots: {
    base: [
      '!rounded-lg', // override breakpoints
      'data-[fill]:!aspect-[0.72/1]',
      'pt-2',

      'sm:pt-4',

      'group-[&:nth-child(n+1)]/slide:bg-yellow',
      'group-[&:nth-child(n+2)]/slide:bg-red',
      'group-[&:nth-child(n+3)]/slide:bg-blue',
    ],
  },
});

const ExpertSlider = tw.theme({
  extend: BaseExpertSliderTheme,
  slots: {
    base: ['bg-white', 'rounded-2xl', 'p-grid-m'],
    headline: ['text-headline-3xs', 'uppercase'],
    caption: ['text-red', 'text-tag-sm', 'my-1.5', 'block'],
    description: ['text-headline-2xs', 'md:text-headline-xs'],
  },
});

export default Object.assign(ExpertSlider, { Image, Slider });
