import { tw } from '@/utils/tw';
import BaseFactBox from 'base/components/FactBox/theme';

const { Prose } = BaseFactBox;

const FactBox = tw.theme({
  extend: BaseFactBox,
  slots: {
    base: ['rounded-lg', 'bg-yellow-300'],
    headline: ['text-gray-950', 'mb-3', 'text-headline-sm', 'sm:text-headline-md', 'normal-case'],
  },
});

export default Object.assign(FactBox, { Prose });
