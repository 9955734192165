/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { HantLabradorDirektTeaser as ResolvedLabradorDirektTeaser } from 'sites/hant/components/labrador/DirektTeaser';

export const LabradorDirektTeaser: typeof ResolvedLabradorDirektTeaser = withLabradorInstanceof((props) => {
    return <ResolvedLabradorDirektTeaser {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorDirektTeaserProps = PropsFromComponent<typeof LabradorDirektTeaser>;
