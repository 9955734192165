import { StandaloneQuotebox } from 'base/components/Quotebox';
import { Quotebox } from './Quotebox';

export const HantStandaloneQuotebox: typeof StandaloneQuotebox = ({ author, quote, quotationMark, ...props }) => {
  return (
    <Quotebox xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <clipPath id="clipCircle">
          <circle cx="50%" cy="50%" r="50%" />
        </clipPath>
      </defs>

      <foreignObject x="0" y="0" width="100%" height="100%" clipPath="url(#clipCircle)">
        <Quotebox.Quote>
          {quotationMark ?? '“'}
          {quote}
          {quotationMark ?? '”'}
        </Quotebox.Quote>
      </foreignObject>
    </Quotebox>
  );
};
