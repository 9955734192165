import { tw } from '@/utils/tw';
import BaseDirectTeaserTheme from 'base/components/DirektTeaser/theme';

export default Object.assign(
  tw.theme({
    extend: BaseDirectTeaserTheme,
    slots: {
      base: ['p-grid-m', 'rounded-2xl', 'bg-white', 'border-0', 'gap-y-3', 'flex', 'flex-col', 'items-start'],
      headline: [],
      header: ['text-headline-3xs', 'uppercase'],
      list: ['border-y', 'w-full'],
      item: ['py-3', 'my-0', 'last:border-0'],
      time: ['text-tag-sm', 'text-red'],
      itemText: ['text-headline-xs'],
    },
  }),
  {
    Blip: BaseDirectTeaserTheme.Blip,
    Button: tw.theme({
      base: ['text-tag-sm', 'uppercase', 'flex', 'flex-row', 'items-center', 'gap-1.5'],
    }),
    Link: BaseDirectTeaserTheme.Link,
  },
);
