import { tw } from '@/utils/tw';
import BaseMorePagesTitleTheme from 'base/components/MorePagesTitle/theme';

const MorePagesTitle = tw.theme({
  extend: BaseMorePagesTitleTheme,
  base: [
    'mx-auto',
    'my-grid-gap',
    'flex',
    'max-w-content',
    'flex-row',
    'items-center',
    'gap-3',
    'whitespace-nowrap',
    'text-gray-300',
    'text-body-xs',
    'before:h-[0.5px]',
    'before:w-full',
    'before:bg-gray-300',
    'after:h-[0.5px]',
    'after:w-full',
    'after:bg-gray-300',
  ],
});

export default MorePagesTitle;
