import { LabradorComponent } from '@/types/component';

import { LabradorBox } from 'base/components/labrador/Box';

const colorsDictionary = {
  'bg-hant-pink': 'bg-red',
  'bg-primary-100': 'bg-red',
  'bg-primary-700': 'bg-red',

  'bg-hant-yellow': 'bg-yellow',
  'bg-yellow-100': 'bg-yellow',

  'bg-hant-blue': 'bg-blue',
  'bg-blue-100': 'bg-blue',
};

export const HantLabradorBox: LabradorComponent = (props) => {
  const { backgroundColor } = props?.meta ?? {};

  ['mobile', 'desktop'].forEach((key) => {
    const color = backgroundColor?.[key];

    if (!color) {
      return;
    }

    backgroundColor[key] = colorsDictionary[color as keyof typeof colorsDictionary] ?? color;
  });

  return <LabradorBox {...props} />;
};
