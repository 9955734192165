import { tw } from '@/utils/tw';
import BaseRadioButtonTheme from 'base/components/RadioButton/theme';

const RadioButton = tw.theme({
  extend: BaseRadioButtonTheme,
  slots: {
    label: ['text-input-md', 'ml-1.5'],
    input: [
      'bg-white',
      'border-gray-600',
      'relative',
      'p-0',

      'after:w-full',
      'after:h-full',
      'after:absolute',
      'after:top-0',
      'after:left-0',
      'after:origin-center',
      'after:scale-0',
      'after:rounded-full',
      'after:bg-gray-600',

      'active:p-0',
      'active:bg-gray-200',
      'active:border-gray-950',
      'active:border-2',

      'focus:p-0',
      'focus:bg-white',
      'focus:border-blue-900',

      'hover:p-0',
      'hover:bg-gray-50',
      'hover:border-gray-950',
      'hover:border-1',

      'checked:bg-white',
      'checked:after:scale-50',
      'checked:active:bg-gray-200',
      'checked:active:after:bg-gray-950',
      'checked:focus:bg-white',
      'checked:focus:after:bg-gray-950',
      'checked:hover:bg-gray-50',
      'checked:hover:after:bg-gray-950',
    ],
  },
});

export default RadioButton;
