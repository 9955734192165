import { ExpertSliderProps } from '@/components/ExpertSlider';
import ElinJonassonImage from '@/hant/public/image/experts/elin-jonasson.png';
import FransStrandbergImage from '@/hant/public/image/experts/frans-strandberg.png';
import LeifSchulmanImage from '@/hant/public/image/experts/leif-schulman.png';
import { mergeProps } from '@/utils/merge';
import { StandaloneExpertSlider } from 'base/components/ExpertSlider';

const slides: ExpertSliderProps['slides'] = [
  {
    image: { src: FransStrandbergImage, alt: 'Krönikör' },
    caption: 'Krönikör',
    description: 'Tramsfrans är Sveriges vassaste nöjespenna och tar dig med in bakom rubrikerna',
    href: '/vara-skribenter/frans_strandberg',
  },
  {
    image: { src: ElinJonassonImage, alt: 'Programledare' },
    caption: 'Programledare',
    description: 'Följ med Elin Pierina och Jennifer Iversen in i Hänt TV:s glittriga och spännande kändisvärld',
    href: '/video',
  },

  {
    image: { src: LeifSchulmanImage, alt: 'TV-redaktör' },
    caption: 'TV-redaktör',
    description: 'Legendaren Leif Schulman är först med de senaste nyheterna från tv och serievärlden',
    href: '/vara-skribenter/leif_schulman',
  },
];

export const HantStandaloneExpertSlider: typeof StandaloneExpertSlider = (props) => {
  return <StandaloneExpertSlider {...mergeProps(props, { headline: 'Våra nöjesexperter', slides })} />;
};
