import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTagsIndexTheme from 'base/components/TagsIndex/theme';

const Tag = tw.theme({
  extend: TagTheme,
  variants: {
    variant: {
      text: ['normal-case', 'hover:underline'],
    },
    size: {
      text: ['text-body-sm'],
    },
  },
  defaultVariants: {
    variant: 'text',
    size: 'text',
    colors: 'text',
  },
});

const TagsIndex = tw.theme({
  extend: BaseTagsIndexTheme,
  slots: {
    headline: ['my-3', 'p-0', 'border-none', 'text-headline-xl', 'md:text-headline-2xl'],
    letter: ['my-3', 'text-headline-md', 'md:text-headline-xl'],
    tags: ['mb-3'],
  },
});

export default Object.assign(TagsIndex, { Tag });
