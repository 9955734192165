import { tw } from '@/utils/tw';
import { default as BaseDirektTheme } from 'base/components/Direkt/theme';

const Blip = tw.theme({
  extend: BaseDirektTheme.Blip,
  base: ['mt-1.5', 'bg-white', 'before:bg-white', 'after:bg-white'],
});

const DirektTheme = tw.theme({
  extend: BaseDirektTheme,
  slots: {
    base: ['max-w-content', '-mx-grid', 'sm:mx-auto', 'bg-white'],
    group: ['mb-0', 'p-3', 'pb-0', 'bg-red-700', 'text-white'],
    heading: ['m-0', 'text-headline-xl'],
    description: ['mb-6', 'p-3', 'pt-0', 'bg-red-700', 'text-white'],
  },
});

export default Object.assign(DirektTheme, { Blip });
