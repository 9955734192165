import { tw } from '@/utils/tw';
import TextAreaTheme from 'base/components/TextArea/theme';

const Caption = tw.theme({
  extend: TextAreaTheme.Caption,
  base: ['text-input-sm'],
});

const TextArea = tw.theme({
  extend: TextAreaTheme,
  slots: {
    label: ['text-gray-950'],
    input: [
      'p-2.5',
      'text-input-md',
      'ring-gray-600',
      'placeholder:text-gray-600',

      'group-data-[status=error]:outline-0',
      'group-data-[status=error]:ring-red-700',

      'hover:not-focus:bg-gray-50',
      'hover:not-focus:ring-gray-950',

      'focus:outline-0',
      'focus:ring-blue-900',
      'focus:bg-white',
    ],
  },
  variants: {
    variant: {
      primary: {
        label: ['text-input-sm'],
      },
      secondary: {
        label: [
          'text-input-md',

          'group-data-[filled]:text-input-xs',

          'group-data-[status=error]:text-red-700',

          'group-focus-within:text-input-xs',
          'group-focus-within:text-blue-900',
        ],
        input: ['pt-8'],
      },
    },
  },
});

export default Object.assign(TextArea, { Caption });
