/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { HantStandaloneArticleTeaser as ResolvedStandaloneArticleTeaser } from 'sites/hant/components/standalone/ArticleTeaser';

export const ArticleTeaser: typeof ResolvedStandaloneArticleTeaser = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneArticleTeaser {...props} />
        </ErrorBoundary>
    );
});

export type ArticleTeaserProps = PropsFromComponent<typeof ArticleTeaser>;

/** @deprecated Use slot architecture instead */
export const ArticleTeaserWith = (extras: DynamicStandaloneExtras): typeof ArticleTeaser => {
    return function ArticleTeaser(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleTeaser');
        return <ResolvedStandaloneArticleTeaser {...mergeProps({ options: { theme } }, props)} />;
    }
}