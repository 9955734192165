import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ComponentProps } from 'react';
import SubscriptionDetails from './SubscriptionDetails';

export interface StandaloneSubscriptionDetailsProps extends StandaloneComponentProps<typeof SubscriptionDetails> {
  digitalSubscriptionAccordionProps?: ComponentProps<typeof SubscriptionDetails.Accordion>;
  printSubscriptionAccordionProps?: ComponentProps<typeof SubscriptionDetails.Accordion>;
}

export const StandaloneSubscriptionDetails: StandaloneComponent<StandaloneSubscriptionDetailsProps> = ({
  digitalSubscriptionAccordionProps,
  printSubscriptionAccordionProps,
  ...props
}) => {
  return (
    <SubscriptionDetails {...props}>
      {(digitalSubscriptionAccordionProps || printSubscriptionAccordionProps) && (
        <SubscriptionDetails.AccordionGroup>
          {digitalSubscriptionAccordionProps && (
            <SubscriptionDetails.Accordion
              {...mergeProps(digitalSubscriptionAccordionProps, {
                options: props.options?.$accordion,
              })}
            />
          )}
          {printSubscriptionAccordionProps && (
            <SubscriptionDetails.Accordion
              {...mergeProps(printSubscriptionAccordionProps, {
                options: props.options?.$accordion,
              })}
            />
          )}
        </SubscriptionDetails.AccordionGroup>
      )}
    </SubscriptionDetails>
  );
};
