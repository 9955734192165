import { tw } from '@/utils/tw';
import DialogTheme from 'base/components/Dialog/theme';

const { CloseIcon, DialogIcon, CloseButton, Button } = DialogTheme;

const Dialog = tw.theme({
  extend: DialogTheme,
  slots: {
    base: ['backdrop:bg-black/60', 'bg-gray-100'],
  },
});

export default Object.assign(Dialog, { CloseIcon, DialogIcon, CloseButton, Button });
