import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseArticleHeaderTheme from 'base/components/ArticleHeader/theme';

const ContactUsLink = tw.theme({
  extend: BaseArticleHeaderTheme.ContactUsLink,
  slots: {
    base: `text-blue text-headline-3xs [&>svg]:text-black`,
  },
});

const Tag = tw.theme({
  extend: TagTheme,
  variants: {
    colors: {
      text: `text-red`,
    },
    size: {
      text: '!text-tag-sm', // override breakpoints
    },
    variant: {
      text: 'inline-flex uppercase',
    },
  },
});

const ArticleHeader = tw.theme({
  extend: BaseArticleHeaderTheme,
  variants: {
    variant: {
      first: {
        base: `group mx-auto grid w-full max-w-grid grid-cols-[auto_1fr] gap-x-3 gap-y-0`,
        bylines: `mt-1.5 flex-wrap gap-3`,
        description: `w-full text-preamble-sm sm:text-preamble-md`,
        headline: `mb-3 text-headline-xl sm:text-headline-2xl`,
        media: `my-6 [&_img]:aspect-default [&_img]:object-cover`,
        tags: `order-1 col-span-2 mb-1.5 flex gap-3 sm:mb-2`,
        date: `col-span-1`,
      },
    },
  },
  defaultVariants: {
    variant: 'first',
  },
});

export default Object.assign(ArticleHeader, { ContactUsLink, Tag });
