import { tw } from '@/utils/tw';
import BaseHighlightedArticlesTheme from 'base/components/HighlightedArticles/theme';

const Blip = tw.theme({
  extend: BaseHighlightedArticlesTheme.Blip,
  base: 'hidden',
});

const Icon = tw.theme({
  extend: BaseHighlightedArticlesTheme.Icon,
  base: ['relative', 'top-0', 'right-0', 'translate-y-0'],
  defaultVariants: {
    size: 'extraSmall',
  },
});

const Skeleton = tw.theme({
  extend: BaseHighlightedArticlesTheme.Skeleton,
  base: ['h-10', 'md:h-10', 'max-w-content', 'mx-auto', 'rounded-2xl'],
});

const HighlightedArticles = tw.theme({
  extend: BaseHighlightedArticlesTheme,
  variants: {
    variant: {
      primary: {
        base: [
          '!h-10', // override breakpoints
          'bg-none',
          'mx-auto',
        ],
        item: [
          'h-full',
          'my-0',
          '!px-2.5', // override blip related paddings
          'bg-white',
          'rounded-2xl',
          '!flex', // override line-clamp
          'flex-row',
          'justify-start',
          'items-center',
        ],
        headline: ['text-headline-2xs', 'md:text-headline-2xs', 'line-clamp-1', 'grow'],
        label: ['text-red', 'text-headline-2xs', 'md:text-headline-2xs', 'whitespace-nowrap'],
      },
    },
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
