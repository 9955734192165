import { tw } from '@/utils/tw';
import BaseArticleFooterTheme from 'base/components/ArticleFooter/theme';

const Tag = tw.theme({
  extend: BaseArticleFooterTheme.Tag,
});

const ArticleFooter = tw.theme({
  extend: BaseArticleFooterTheme,
  slots: {
    base: ['mb-3'],
  },
});

export default Object.assign(ArticleFooter, { Tag });
