import { tw } from '@/utils/tw';
import BaseAuthorsListTheme from 'base/components/AuthorsList/theme';

const ListItemImage = tw.theme({
  extend: BaseAuthorsListTheme.ListItemImage,
  slots: {
    base: ['rounded-full'],
  },
});

const AuthorsListTheme = tw.theme({
  extend: BaseAuthorsListTheme,
  slots: {
    base: ['gap-y-0', 'max-w-content', 'mx-auto'],
    headline: ['text-headline-xl', 'my-6'],
    list: ['grid', 'grid-cols-1', 'gap-6', 'mb-6', 'sm:grid-cols-2', 'md:grid-cols-3'],
    listItem: ['w-full'],
  },
  variants: {
    colors: {
      default: { listItem: ['bg-gray-100', 'hover:gray-200', 'active:bg-gray-100'] },
    },
  },
});

export default Object.assign(AuthorsListTheme, {
  ListItemLink: BaseAuthorsListTheme.ListItemLink,
  ListItemImage,
  ListItemIcon: BaseAuthorsListTheme.ListItemIcon,
});
