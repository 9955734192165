import { tw } from '@/utils/tw';
import ScrollArticleLabelTheme from 'base/components/ScrollArticleLabel/theme';

const ScrollArticleLabel = tw.theme({
  extend: ScrollArticleLabelTheme,
  base: [
    'text-body-xs',
    'max-w-content',
    'mx-auto',
    'text-gray-300',
    'before:bg-gray-300',
    'before:h-[0.5px]',
    'after:bg-gray-300',
    'after:h-[0.5px]',
  ],
});

export default ScrollArticleLabel;
