import type { HighlightedArticlesProps } from '@/components/HighlightedArticles';

import { mergeProps } from '@/utils/merge';
import { StandaloneHighlightedArticles } from 'base/components/HighlightedArticles';

export const HantStandaloneHighlightedArticles: typeof StandaloneHighlightedArticles = (props) => {
  return (
    <StandaloneHighlightedArticles
      {...mergeProps(
        {
          options: {
            $swiper: {
              className: 'max-w-content mx-auto rounded h-full w-full',
              spaceBetween: 12,
              direction: 'vertical',
              effect: 'creative',
              speed: 900,
              creativeEffect: {
                prev: {
                  translate: [0, '-120%', -100],
                },
                next: {
                  translate: [0, '100%', 0],
                },
              },
            },
            $slide: {},
          },
        } as HighlightedArticlesProps,
        props,
      )}
    />
  );
};
