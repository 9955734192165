import { tw } from '@/utils/tw';
import BasePaginationTheme from 'base/components/Pagination/theme';

const Icon = tw.theme({
  extend: BasePaginationTheme.Icon,
  base: `[&_path]:fill-black`,
});

const Pagination = tw.theme({
  extend: BasePaginationTheme,
  slots: {
    button: `text-headline-xs data-[active=true]:bg-black data-[active=true]:text-white sm:text-headline-sm`,
    dots: `text-headline-xs sm:text-headline-sm`,
  },
});

export default Object.assign(Pagination, { Icon });
