import { tw } from '@/utils/tw';
import BaseRecommendedArticlesTheme from 'base/components/RecommendedArticles/theme';

const Skeleton = tw.theme({
  extend: BaseRecommendedArticlesTheme.Skeleton,
  base: ['h-[188px]'],
});

const ArticleTeaser = tw.theme({
  extend: BaseRecommendedArticlesTheme.ArticleTeaser,
  slots: {
    base: ['rounded-none', '!gap-2', 'grid-cols-[auto_100px]', '[&_picture]:rounded-xl', '[&_picture]:overflow-hidden'],
    description: ['hidden'],
    headline: ['!text-headline-2xs', 'md:!text-headline-xs'],
    caption: ['text-tag-sm', 'text-red'],
    group: ['!p-0', 'max-sm:mr-6', 'max-sm:col-start-1', 'max-sm:row-start-1'],
  },
});

export default Object.assign(
  tw.theme({
    extend: BaseRecommendedArticlesTheme,
    slots: {
      base: ['relative', 'bg-white', '!p-3', 'rounded-2xl', 'max-w-content', 'mx-auto', 'wings-none', 'w-full'],
      headline: ['block', 'uppercase', '!text-headline-3xs', 'mb-3'],
      list: ['gap-3'],
    },
  }),
  { ArticleTeaser, Skeleton },
);
